import React from 'react'

const Foot = () => {
    return (
        <>
            <div
                className="modal bg-white fade"
                id="kt_header_search_modal"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-fullscreen">
                    <div className="modal-content shadow-none">
                        <div className="container w-lg-800px">
                            <div className="modal-header d-flex justify-content-end border-0">
                                {/*begin::Close*/}
                                <div
                                    className="btn btn-icon btn-sm btn-light ms-2"
                                    data-bs-dismiss="modal"
                                >
                                    {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                                    <span className="svg-icon svg-icon-1">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                        >
                                            <rect
                                                opacity="0.5"
                                                x={6}
                                                y="17.3137"
                                                width={16}
                                                height={2}
                                                rx={1}
                                                transform="rotate(-45 6 17.3137)"
                                                fill="black"
                                            />
                                            <rect
                                                x="7.41422"
                                                y={6}
                                                width={16}
                                                height={2}
                                                rx={1}
                                                transform="rotate(45 7.41422 6)"
                                                fill="black"
                                            />
                                        </svg>
                                    </span>
                                    {/*end::Svg Icon*/}
                                </div>
                                {/*end::Close*/}
                            </div>
                            <div className="modal-body">
                                {/*begin::Search*/}
                                <form className="pb-10">
                                    <input
                                        autoFocus=""
                                        type="text"
                                        className="form-control bg-transparent border-0 fs-4x text-center fw-normal"
                                        name="query"
                                        placeholder="Search..."
                                    />
                                </form>
                                {/*end::Search*/}
                                {/*begin::Vendors*/}
                                <div>
                                    <h3 className="text-dark fw-bolder fs-1 mb-6">Vendors</h3>
                                    {/*begin::List Widget 4*/}
                                    <div className="card bg-transparent mb-5 shadow-none">
                                        {/*begin::Body*/}
                                        <div className="card-body pt-2 px-0">
                                            <div className="table-responsive">
                                                <table className="table table-borderless align-middle">
                                                    {/*begin::Item*/}
                                                    <tbody>
                                                        <tr>
                                                            <td className="ps-0 flex-column min-w-300px">
                                                                {/*begin::Title*/}
                                                                <a
                                                                    href="#"
                                                                    className="text-gray-800 fw-bolder text-hover-primary fs-6 mb-1"
                                                                >
                                                                    Brad Simmons
                                                                </a>
                                                                <div className="text-muted fw-bold">
                                                                    Company Name A
                                                                </div>
                                                                {/*end::Title*/}
                                                            </td>
                                                            <td className="text-end pe-0">
                                                                {/*begin::Btn*/}
                                                                <a
                                                                    href="#"
                                                                    className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                                                                >
                                                                    {/*begin::Svg Icon | path: icons/duotune/arrows/arr064.svg*/}
                                                                    <span className="svg-icon svg-icon-4">
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width={24}
                                                                            height={24}
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                        >
                                                                            <rect
                                                                                opacity="0.5"
                                                                                x={18}
                                                                                y={13}
                                                                                width={13}
                                                                                height={2}
                                                                                rx={1}
                                                                                transform="rotate(-180 18 13)"
                                                                                fill="black"
                                                                            />
                                                                            <path
                                                                                d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                                                                fill="black"
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                    {/*end::Svg Icon*/}
                                                                </a>
                                                                {/*end::Btn*/}
                                                            </td>
                                                        </tr>
                                                        {/*end::Item*/}
                                                        {/*begin::Item*/}
                                                        <tr>
                                                            <td className="ps-0 flex-column min-w-300px">
                                                                {/*begin::Title*/}
                                                                <a
                                                                    href="#"
                                                                    className="text-gray-800 fw-bolder text-hover-primary fs-6 mb-1"
                                                                >
                                                                    Jessie Clarcson
                                                                </a>
                                                                <div className="text-muted fw-bold">
                                                                    Company Name B
                                                                </div>
                                                                {/*end::Title*/}
                                                            </td>
                                                            <td className="text-end pe-0">
                                                                {/*begin::Btn*/}
                                                                <a
                                                                    href="#"
                                                                    className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                                                                >
                                                                    {/*begin::Svg Icon | path: icons/duotune/arrows/arr064.svg*/}
                                                                    <span className="svg-icon svg-icon-4">
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width={24}
                                                                            height={24}
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                        >
                                                                            <rect
                                                                                opacity="0.5"
                                                                                x={18}
                                                                                y={13}
                                                                                width={13}
                                                                                height={2}
                                                                                rx={1}
                                                                                transform="rotate(-180 18 13)"
                                                                                fill="black"
                                                                            />
                                                                            <path
                                                                                d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                                                                fill="black"
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                    {/*end::Svg Icon*/}
                                                                </a>
                                                                {/*end::Btn*/}
                                                            </td>
                                                        </tr>
                                                        {/*end::Item*/}
                                                        {/*begin::Item*/}
                                                        <tr>
                                                            <td className="ps-0 flex-column min-w-300px">
                                                                {/*begin::Title*/}
                                                                <a
                                                                    href="#"
                                                                    className="text-gray-800 fw-bolder text-hover-primary fs-6 mb-1"
                                                                >
                                                                    Lebron Wayde
                                                                </a>
                                                                <div className="text-muted fw-bold">
                                                                    Company Name C
                                                                </div>
                                                                {/*end::Title*/}
                                                            </td>
                                                            <td className="text-end pe-0">
                                                                {/*begin::Btn*/}
                                                                <a
                                                                    href="#"
                                                                    className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                                                                >
                                                                    {/*begin::Svg Icon | path: icons/duotune/arrows/arr064.svg*/}
                                                                    <span className="svg-icon svg-icon-4">
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width={24}
                                                                            height={24}
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                        >
                                                                            <rect
                                                                                opacity="0.5"
                                                                                x={18}
                                                                                y={13}
                                                                                width={13}
                                                                                height={2}
                                                                                rx={1}
                                                                                transform="rotate(-180 18 13)"
                                                                                fill="black"
                                                                            />
                                                                            <path
                                                                                d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                                                                fill="black"
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                    {/*end::Svg Icon*/}
                                                                </a>
                                                                {/*end::Btn*/}
                                                            </td>
                                                        </tr>
                                                        {/*end::Item*/}
                                                        {/*begin::Item*/}
                                                        <tr>
                                                            <td className="ps-0 flex-column min-w-300px">
                                                                {/*begin::Title*/}
                                                                <a
                                                                    href="#"
                                                                    className="text-gray-800 fw-bolder text-hover-primary fs-6 mb-1"
                                                                >
                                                                    Kevin Leonard
                                                                </a>
                                                                <div className="text-muted fw-bold">
                                                                    Company Name D
                                                                </div>
                                                                {/*end::Title*/}
                                                            </td>
                                                            <td className="text-end pe-0">
                                                                {/*begin::Btn*/}
                                                                <a
                                                                    href="#"
                                                                    className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                                                                >
                                                                    {/*begin::Svg Icon | path: icons/duotune/arrows/arr064.svg*/}
                                                                    <span className="svg-icon svg-icon-4">
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width={24}
                                                                            height={24}
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                        >
                                                                            <rect
                                                                                opacity="0.5"
                                                                                x={18}
                                                                                y={13}
                                                                                width={13}
                                                                                height={2}
                                                                                rx={1}
                                                                                transform="rotate(-180 18 13)"
                                                                                fill="black"
                                                                            />
                                                                            <path
                                                                                d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                                                                fill="black"
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                    {/*end::Svg Icon*/}
                                                                </a>
                                                                {/*end::Btn*/}
                                                            </td>
                                                        </tr>
                                                        {/*end::Item*/}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        {/*end::Body*/}
                                    </div>
                                    {/*end::List Widget 4*/}
                                </div>
                                <div>
                                    <h3 className="text-dark fw-bolder fs-1 mb-6">Referrals</h3>
                                    {/*begin::List Widget 4*/}
                                    <div className="card bg-transparent mb-5 shadow-none">
                                        {/*begin::Body*/}
                                        <div className="card-body pt-2 px-0">
                                            <div className="table-responsive">
                                                <table className="table table-borderless align-middle">
                                                    {/*begin::Item*/}
                                                    <tbody>
                                                        <tr>
                                                            <td className="ps-0 flex-column min-w-300px">
                                                                {/*begin::Title*/}
                                                                <a
                                                                    href="#"
                                                                    className="text-gray-800 fw-bolder text-hover-primary fs-6 mb-1"
                                                                >
                                                                    Brad Simmons
                                                                </a>
                                                                <div className="text-muted fw-bold">
                                                                    Vendor Name: Mikaela Collins
                                                                </div>
                                                                {/*end::Title*/}
                                                            </td>
                                                            <td className="text-end pe-0">
                                                                {/*begin::Btn*/}
                                                                <a
                                                                    href="#"
                                                                    className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                                                                >
                                                                    {/*begin::Svg Icon | path: icons/duotune/arrows/arr064.svg*/}
                                                                    <span className="svg-icon svg-icon-4">
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width={24}
                                                                            height={24}
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                        >
                                                                            <rect
                                                                                opacity="0.5"
                                                                                x={18}
                                                                                y={13}
                                                                                width={13}
                                                                                height={2}
                                                                                rx={1}
                                                                                transform="rotate(-180 18 13)"
                                                                                fill="black"
                                                                            />
                                                                            <path
                                                                                d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                                                                fill="black"
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                    {/*end::Svg Icon*/}
                                                                </a>
                                                                {/*end::Btn*/}
                                                            </td>
                                                        </tr>
                                                        {/*end::Item*/}
                                                        {/*begin::Item*/}
                                                        <tr>
                                                            <td className="ps-0 flex-column min-w-300px">
                                                                {/*begin::Title*/}
                                                                <a
                                                                    href="#"
                                                                    className="text-gray-800 fw-bolder text-hover-primary fs-6 mb-1"
                                                                >
                                                                    Jessie Clarcson
                                                                </a>
                                                                <div className="text-muted fw-bold">
                                                                    Vendor Name: Ana Crown
                                                                </div>
                                                                {/*end::Title*/}
                                                            </td>
                                                            <td className="text-end pe-0">
                                                                {/*begin::Btn*/}
                                                                <a
                                                                    href="#"
                                                                    className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                                                                >
                                                                    {/*begin::Svg Icon | path: icons/duotune/arrows/arr064.svg*/}
                                                                    <span className="svg-icon svg-icon-4">
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width={24}
                                                                            height={24}
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                        >
                                                                            <rect
                                                                                opacity="0.5"
                                                                                x={18}
                                                                                y={13}
                                                                                width={13}
                                                                                height={2}
                                                                                rx={1}
                                                                                transform="rotate(-180 18 13)"
                                                                                fill="black"
                                                                            />
                                                                            <path
                                                                                d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                                                                fill="black"
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                    {/*end::Svg Icon*/}
                                                                </a>
                                                                {/*end::Btn*/}
                                                            </td>
                                                        </tr>
                                                        {/*end::Item*/}
                                                        {/*begin::Item*/}
                                                        <tr>
                                                            <td className="ps-0 flex-column min-w-300px">
                                                                {/*begin::Title*/}
                                                                <a
                                                                    href="#"
                                                                    className="text-gray-800 fw-bolder text-hover-primary fs-6 mb-1"
                                                                >
                                                                    Lebron Wayde
                                                                </a>
                                                                <div className="text-muted fw-bold">
                                                                    Vendor Name: Mikaela Collins
                                                                </div>
                                                                {/*end::Title*/}
                                                            </td>
                                                            <td className="text-end pe-0">
                                                                {/*begin::Btn*/}
                                                                <a
                                                                    href="#"
                                                                    className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                                                                >
                                                                    {/*begin::Svg Icon | path: icons/duotune/arrows/arr064.svg*/}
                                                                    <span className="svg-icon svg-icon-4">
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width={24}
                                                                            height={24}
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                        >
                                                                            <rect
                                                                                opacity="0.5"
                                                                                x={18}
                                                                                y={13}
                                                                                width={13}
                                                                                height={2}
                                                                                rx={1}
                                                                                transform="rotate(-180 18 13)"
                                                                                fill="black"
                                                                            />
                                                                            <path
                                                                                d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                                                                fill="black"
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                    {/*end::Svg Icon*/}
                                                                </a>
                                                                {/*end::Btn*/}
                                                            </td>
                                                        </tr>
                                                        {/*end::Item*/}
                                                        {/*begin::Item*/}
                                                        <tr>
                                                            <td className="ps-0 flex-column min-w-300px">
                                                                {/*begin::Title*/}
                                                                <a
                                                                    href="#"
                                                                    className="text-gray-800 fw-bolder text-hover-primary fs-6 mb-1"
                                                                >
                                                                    Kevin Leonard
                                                                </a>
                                                                <div className="text-muted fw-bold">
                                                                    Vendor Name: Emma Smith
                                                                </div>
                                                                {/*end::Title*/}
                                                            </td>
                                                            <td className="text-end pe-0">
                                                                {/*begin::Btn*/}
                                                                <a
                                                                    href="#"
                                                                    className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                                                                >
                                                                    {/*begin::Svg Icon | path: icons/duotune/arrows/arr064.svg*/}
                                                                    <span className="svg-icon svg-icon-4">
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width={24}
                                                                            height={24}
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                        >
                                                                            <rect
                                                                                opacity="0.5"
                                                                                x={18}
                                                                                y={13}
                                                                                width={13}
                                                                                height={2}
                                                                                rx={1}
                                                                                transform="rotate(-180 18 13)"
                                                                                fill="black"
                                                                            />
                                                                            <path
                                                                                d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                                                                fill="black"
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                    {/*end::Svg Icon*/}
                                                                </a>
                                                                {/*end::Btn*/}
                                                            </td>
                                                        </tr>
                                                        {/*end::Item*/}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        {/*end::Body*/}
                                    </div>
                                    {/*end::List Widget 4*/}
                                </div>
                                {/*end::Framework Users*/}
                                {/*begin::Tutorials*/}
                                <div className="pb-10" style={{ display: "none" }}>
                                    <h3 className="text-dark fw-bolder fs-1 mb-6">Companies</h3>
                                    {/*begin::List Widget 5*/}
                                    <div className="card mb-5 shadow-none">
                                        {/*begin::Body*/}
                                        <div className="card-body pt-2 px-0">
                                            {/*begin::Item*/}
                                            <div className="d-flex mb-6">
                                                {/*begin::Content*/}
                                                <div className="d-flex flex-column">
                                                    <a
                                                        href="#"
                                                        className="fs-6 fw-bolder text-hover-primary text-gray-800 mb-2"
                                                    >
                                                        Copany Name A
                                                    </a>
                                                </div>
                                                {/*end::Content*/}
                                            </div>
                                            {/*end::Item*/}
                                            {/*begin::Item*/}
                                            <div className="d-flex mb-6">
                                                {/*begin::Content*/}
                                                <div className="d-flex flex-column">
                                                    <a
                                                        href="#"
                                                        className="fs-6 fw-bolder text-hover-primary text-gray-800 mb-2"
                                                    >
                                                        Copany Name B
                                                    </a>
                                                </div>
                                                {/*end::Content*/}
                                            </div>
                                            {/*end::Item*/}
                                            {/*begin::Item*/}
                                            <div className="d-flex mb-6">
                                                {/*begin::Content*/}
                                                <div className="d-flex flex-column">
                                                    <a
                                                        href="#"
                                                        className="fs-6 fw-bolder text-hover-primary text-gray-800 mb-2"
                                                    >
                                                        Copany Name C
                                                    </a>
                                                </div>
                                                {/*end::Content*/}
                                            </div>
                                            {/*end::Item*/}
                                            {/*begin::Item*/}
                                            <div className="d-flex mb-6">
                                                {/*begin::Content*/}
                                                <div className="d-flex flex-column">
                                                    <a
                                                        href="#"
                                                        className="fs-6 fw-bolder text-hover-primary text-gray-800 mb-2"
                                                    >
                                                        Copany Name D
                                                    </a>
                                                </div>
                                                {/*end::Content*/}
                                            </div>
                                            {/*end::Item*/}
                                            {/*begin::Item*/}
                                            <div className="d-flex mb-6">
                                                {/*begin::Content*/}
                                                <div className="d-flex flex-column">
                                                    <a
                                                        href="#"
                                                        className="fs-6 fw-bolder text-hover-primary text-gray-800 mb-2"
                                                    >
                                                        Copany Name E
                                                    </a>
                                                </div>
                                                {/*end::Content*/}
                                            </div>
                                            {/*end::Item*/}
                                        </div>
                                        {/*end::Body*/}
                                    </div>
                                    {/*end::List Widget 5*/}
                                </div>
                                {/*end::Tutorials*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*end::Header Search*/}
            {/*begin::Mega Menu*/}
            <div
                className="modal bg-white fade"
                id="kt_mega_menu_modal"
                tabIndex={-1}
                aria-hidden="true"
            >
                <div className="modal-dialog modal-fullscreen">
                    <div className="modal-content shadow-none">
                        <div className="container">
                            <div className="modal-header d-flex flex-stack border-0">
                                <div className="d-flex align-items-center">
                                    {/*begin::Logo*/}
                                    <a href="../dist/index.html">
                                        <img
                                            alt="Logo"
                                            src="assets/media/logos/logo-default.svg"
                                            className="h-30px"
                                        />
                                    </a>
                                    {/*end::Logo*/}
                                </div>
                                {/*begin::Close*/}
                                <div
                                    className="btn btn-icon btn-sm btn-light-primary ms-2"
                                    data-bs-dismiss="modal"
                                >
                                    {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                                    <span className="svg-icon svg-icon-1">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                        >
                                            <rect
                                                opacity="0.5"
                                                x={6}
                                                y="17.3137"
                                                width={16}
                                                height={2}
                                                rx={1}
                                                transform="rotate(-45 6 17.3137)"
                                                fill="black"
                                            />
                                            <rect
                                                x="7.41422"
                                                y={6}
                                                width={16}
                                                height={2}
                                                rx={1}
                                                transform="rotate(45 7.41422 6)"
                                                fill="black"
                                            />
                                        </svg>
                                    </span>
                                    {/*end::Svg Icon*/}
                                </div>
                                {/*end::Close*/}
                            </div>
                            <div className="modal-body">
                                {/*begin::Row*/}
                                <div className="row py-10 g-5">
                                    {/*begin::Column*/}
                                    <div className="col-lg-6 pe-lg-25">
                                        {/*begin::Row*/}
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <h3 className="fw-bolder mb-5">Dashboards</h3>
                                                <ul className="menu menu-column menu-fit menu-rounded menu-gray-600 menu-hover-primary menu-active-primary fw-bold fs-6 mb-10">
                                                    <li className="menu-item">
                                                        <a
                                                            className="menu-link ps-0 py-2"
                                                            href="../dist/index.html"
                                                        >
                                                            Start
                                                        </a>
                                                    </li>
                                                    <li className="menu-item">
                                                        <a
                                                            className="menu-link ps-0 py-2"
                                                            href="../dist/dashboards/extended.html"
                                                        >
                                                            Extended
                                                        </a>
                                                    </li>
                                                    <li className="menu-item">
                                                        <a
                                                            className="menu-link ps-0 py-2 active"
                                                            href="../dist/dashboards/light.html"
                                                        >
                                                            Light
                                                        </a>
                                                    </li>
                                                    <li className="menu-item">
                                                        <a
                                                            className="menu-link ps-0 py-2"
                                                            href="../dist/dashboards/compact.html"
                                                        >
                                                            Compact
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="col-sm-4">
                                                <h3 className="fw-bolder mb-5">Apps</h3>
                                                <ul className="menu menu-column menu-fit menu-rounded menu-gray-600 menu-hover-primary menu-active-primary fw-bold fs-6 mb-10">
                                                    <li className="menu-item">
                                                        <a
                                                            className="menu-link ps-0 py-2"
                                                            href="../dist/apps/calendar.html"
                                                        >
                                                            Calendar
                                                        </a>
                                                    </li>
                                                    <li className="menu-item">
                                                        <a
                                                            className="menu-link ps-0 py-2"
                                                            href="../dist/apps/chat/private.html"
                                                        >
                                                            Private Chat
                                                        </a>
                                                    </li>
                                                    <li className="menu-item">
                                                        <a
                                                            className="menu-link ps-0 py-2"
                                                            href="../dist/apps/chat/group.html"
                                                        >
                                                            Group Chat
                                                        </a>
                                                    </li>
                                                    <li className="menu-item">
                                                        <a
                                                            className="menu-link ps-0 py-2"
                                                            href="../dist/apps/chat/drawer.html"
                                                        >
                                                            Drawer Chat
                                                        </a>
                                                    </li>
                                                    <li className="menu-item">
                                                        <a
                                                            className="menu-link ps-0 py-2"
                                                            href="../dist/apps/inbox.html"
                                                        >
                                                            Inbox
                                                        </a>
                                                    </li>
                                                    <li className="menu-item">
                                                        <a
                                                            className="menu-link ps-0 py-2"
                                                            href="../dist/apps/shop/shop-1.html"
                                                        >
                                                            Shop 1
                                                        </a>
                                                    </li>
                                                    <li className="menu-item">
                                                        <a
                                                            className="menu-link ps-0 py-2"
                                                            href="../dist/apps/shop/shop-2.html"
                                                        >
                                                            Shop 2
                                                        </a>
                                                    </li>
                                                    <li className="menu-item">
                                                        <a
                                                            className="menu-link ps-0 py-2"
                                                            href="../dist/apps/shop/product.html"
                                                        >
                                                            Shop Product
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="col-sm-4">
                                                <h3 className="fw-bolder mb-5">General</h3>
                                                <ul className="menu menu-column menu-fit menu-rounded menu-gray-600 menu-hover-primary menu-active-primary fw-bold fs-6 mb-10">
                                                    <li className="menu-item">
                                                        <a
                                                            className="menu-link ps-0 py-2"
                                                            href="../dist/general/faq.html"
                                                        >
                                                            FAQ
                                                        </a>
                                                    </li>
                                                    <li className="menu-item">
                                                        <a
                                                            className="menu-link ps-0 py-2"
                                                            href="../dist/general/pricing.html"
                                                        >
                                                            Pricing
                                                        </a>
                                                    </li>
                                                    <li className="menu-item">
                                                        <a
                                                            className="menu-link ps-0 py-2"
                                                            href="../dist/general/invoice.html"
                                                        >
                                                            Invoice
                                                        </a>
                                                    </li>
                                                    <li className="menu-item">
                                                        <a
                                                            className="menu-link ps-0 py-2"
                                                            href="../dist/general/login.html"
                                                        >
                                                            Login
                                                        </a>
                                                    </li>
                                                    <li className="menu-item">
                                                        <a
                                                            className="menu-link ps-0 py-2"
                                                            href="../dist/general/wizard.html"
                                                        >
                                                            Wizard
                                                        </a>
                                                    </li>
                                                    <li className="menu-item">
                                                        <a
                                                            className="menu-link ps-0 py-2"
                                                            href="../dist/general/error.html"
                                                        >
                                                            Error
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        {/*end::Row*/}
                                        {/*begin::Row*/}
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <h3 className="fw-bolder mb-5">Profile</h3>
                                                <ul className="menu menu-column menu-fit menu-rounded menu-gray-600 menu-hover-primary menu-active-primary fw-bold fs-6 mb-10">
                                                    <li className="menu-item">
                                                        <a
                                                            className="menu-link ps-0 py-2"
                                                            href="../dist/profile/overview.html"
                                                        >
                                                            Overview
                                                        </a>
                                                    </li>
                                                    <li className="menu-item">
                                                        <a
                                                            className="menu-link ps-0 py-2"
                                                            href="../dist/profile/account.html"
                                                        >
                                                            Account
                                                        </a>
                                                    </li>
                                                    <li className="menu-item">
                                                        <a
                                                            className="menu-link ps-0 py-2"
                                                            href="../dist/profile/settings.html"
                                                        >
                                                            Settings
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="col-sm-4">
                                                <h3 className="fw-bolder mb-5">Resources</h3>
                                                <ul className="menu menu-column menu-fit menu-rounded menu-gray-600 menu-hover-primary menu-active-primary fw-bold fs-6 mb-10">
                                                    <li className="menu-item">
                                                        <a
                                                            className="menu-link ps-0 py-2"
                                                            href="../dist/documentation/base/utilities.html"
                                                        >
                                                            Components
                                                        </a>
                                                    </li>
                                                    <li className="menu-item">
                                                        <a
                                                            className="menu-link ps-0 py-2"
                                                            href="../dist/documentation/getting-started.html"
                                                        >
                                                            Documentation
                                                        </a>
                                                    </li>
                                                    <li className="menu-item">
                                                        <a
                                                            className="menu-link ps-0 py-2"
                                                            href="https://preview.keenthemes.com/start/layout-builder.html"
                                                        >
                                                            Layout Builder
                                                        </a>
                                                    </li>
                                                    <li className="menu-item">
                                                        <a
                                                            className="menu-link ps-0 py-2"
                                                            href="../dist/documentation/getting-started/changelog.html"
                                                        >
                                                            Changelog
                                                            <span className="badge badge-changelog badge-light-danger bg-hover-danger text-hover-white fw-bold fs-9 px-2 ms-2">
                                                                v1.0.9
                                                            </span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        {/*end::Row*/}
                                    </div>
                                    {/*end::Column*/}
                                    {/*begin::Column*/}
                                    <div className="col-lg-6">
                                        <h3 className="fw-bolder mb-8">Quick Links</h3>
                                        {/*begin::Row*/}
                                        <div className="row g-5">
                                            <div className="col-sm-4">
                                                <a
                                                    href="#"
                                                    className="card bg-light-success hoverable min-h-125px shadow-none mb-5"
                                                >
                                                    <div className="card-body d-flex flex-column flex-center">
                                                        <h3 className="fs-3 mb-2 text-dark fw-bolder">
                                                            Security
                                                        </h3>
                                                        <p className="mb-0 text-gray-600">$2.99/month</p>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="col-sm-4">
                                                <a
                                                    href="#"
                                                    className="card bg-light-danger hoverable min-h-125px shadow-none mb-5"
                                                >
                                                    <div className="card-body d-flex flex-column flex-center text-center">
                                                        <h3 className="fs-3 mb-2 text-dark fw-bolder">Demo</h3>
                                                        <p className="mb-0 text-gray-600">Free Version</p>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="col-sm-4">
                                                <a
                                                    href="#"
                                                    className="card bg-light-warning hoverable min-h-125px shadow-none mb-5"
                                                >
                                                    <div className="card-body d-flex flex-column flex-center text-center">
                                                        <h3 className="fs-3 mb-2 text-dark text-hover-primary fw-bolder">
                                                            Try Now
                                                        </h3>
                                                        <p className="mb-0 text-gray-600">Pro Version</p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        {/*end::Row*/}
                                        {/*begin::Row*/}
                                        <div className="row g-5">
                                            <div className="col-sm-8">
                                                <a
                                                    href="#"
                                                    className="card bg-light-primary hoverable min-h-125px shadow-none mb-5"
                                                >
                                                    <div className="card-body d-flex flex-column flex-center text-center">
                                                        <h3 className="fs-3 mb-2 text-dark fw-bolder">
                                                            Payment Methods
                                                        </h3>
                                                        <p className="mb-0 text-gray-600">
                                                            Credit Cards/Debit Cards, Paypal,
                                                            <br />
                                                            Transferwise &amp; Others
                                                        </p>
                                                    </div>
                                                </a>
                                                {/*begin::Row*/}
                                                <div className="row g-5">
                                                    <div className="col-sm-6">
                                                        <a className="card bg-light-warning hoverable shadow-none min-h-125px mb-5">
                                                            <div className="card-body d-flex flex-column flex-center text-center">
                                                                <h3 className="fs-3 mb-2 text-dark fw-bolder">
                                                                    Support
                                                                </h3>
                                                                <p className="mb-0 text-gray-600">6 Month Free</p>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <a
                                                            href="#"
                                                            className="card bg-light-success hoverable shadow-none min-h-125px mb-5"
                                                        >
                                                            <div className="card-body d-flex flex-column flex-center text-center">
                                                                <h3 className="fs-3 mb-2 text-dark fw-bolder">
                                                                    Installation
                                                                </h3>
                                                                <p className="mb-0 text-gray-600">
                                                                    $0.99 Per Machine
                                                                </p>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                {/*end::Row*/}
                                            </div>
                                            <div className="col-sm-4">
                                                <a
                                                    href="#"
                                                    className="card card-stretch mb-5 bg-light-info hoverable shadow-none min-h-250px"
                                                >
                                                    <div className="card-body d-flex flex-column p-0">
                                                        <div className="d-flex flex-column flex-grow-1 flex-center text-center px-5 pt-10">
                                                            <h3 className="fs-3 mb-2 text-dark fw-bolder">
                                                                Quick Start
                                                            </h3>
                                                            <p className="mb-0 text-gray-600">
                                                                Single Click Import
                                                            </p>
                                                        </div>
                                                        <div
                                                            className="min-h-125px bgi-no-repeat bgi-size-contain bgi-position-x-center bgi-position-y-bottom card-rounded-bottom"
                                                            style={{
                                                                backgroundImage:
                                                                    'url("assets/media/illustrations/sigma-1/2.png")'
                                                            }}
                                                        ></div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        {/*end::Row*/}
                                    </div>
                                    {/*end::Column*/}
                                </div>
                                {/*end::Row*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*end::Mega Menu*/}
            {/*begin::Scrolltop*/}
            <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
                {/*begin::Svg Icon | path: icons/duotune/arrows/arr066.svg*/}
                <span className="svg-icon">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                    >
                        <rect
                            opacity="0.5"
                            x={13}
                            y={6}
                            width={13}
                            height={2}
                            rx={1}
                            transform="rotate(90 13 6)"
                            fill="black"
                        />
                        <path
                            d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                            fill="black"
                        />
                    </svg>
                </span>
            </div>

        </>

    )
}

export default Foot