import React, { useState, useEffect, useRef, useCallback } from "react";
import Leftsidebar from "../component/leftsidebar";
import Header from "../component/header";
import Footer from "../component/footer";
import Foot from "../component/foot";
import {
  Link,
  Page,
  Card,
  DataTable,
  useBreakpoints,
  Grid,
  LegacyCard,
  Select,
} from "@shopify/polaris";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import "jspdf-autotable";

import * as XLSX from "xlsx/xlsx";
import FileSaver from "file-saver";
import html2pdf from "html2pdf.js";

import { NavLink } from "react-router-dom";
import axios from "axios";
import ReactPaginate from "react-paginate";
import Loader from "../Loader/Loader";
import ReactDOMServer from "react-dom/server";
import "./style.css";
import noimg from "../assets/no_img.jpg";
import { useReactToPrint } from "react-to-print";

const Product = () => {
  const [data, setData] = useState([]);
  // const [alldata, setAlldata] = useState([]);
  const [allOrders, setAllOrders] = useState([]);

  // const [collectionData, setCollectionData] = useState([]);
  const [fDatas, setFDatas] = useState([]);
  // const [vendorList, setVendorList] = useState([]);
  // const [warehouseList, setWarehouseList] = useState([]);
  // const [collOption, setCollOption] = useState([]);
  // const [whList, setWhlist] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [exlData, setexcelData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const conponentPDF = useRef();

  //---------------->>>>>>>>>----------------FILTER----------------<<<<<<<<<<<<----------------

  const handleSearchChange = (e) => {
    setSearchData(e.target.value);
    console.log(setSearchData, "121");
    if (!e.target.value) {
      setData(fDatas);
    }
  };

  const handleSearchClick = (e, data) => {
    console.log(fDatas, "ftattata");
    var filterData =
      !searchData || searchData == ""
        ? fDatas
        : fDatas.filter((filterEl) => {
            return (
              filterEl.title.toLowerCase().includes(searchData.toLowerCase()) ||
              filterEl.id.toLowerCase().includes(searchData.toLowerCase()) ||
              filterEl.title.toLowerCase().includes(searchData.toLowerCase())
            );
          });
    if (e.target.value != "" && filterData) {
      setData(filterData);
    } else {
      setData(fDatas);
    }
  };

  // const handleChangeColl = (e) => {
  //     setCollOption(e.target.value);
  //     var filterData = collectionData.filter((filterEl) => {
  //         return (filterEl.collections.id.toLowerCase().includes(e.target.value.toLowerCase()))
  //     })
  //     if (e.target.value != "" && filterData) {
  //         setData(filterData);
  //     }
  //     else {
  //         setData(fDatas);
  //     }

  // }

  // const vendorHandleChange = (e) => {
  //     setVendorList(e.target.value);
  //     var filterData = fDatas.filter((filterEl) => {
  //         return (filterEl.vendor.toLowerCase().includes(e.target.value.toLowerCase()))
  //     })
  //     if (filterData) {
  //         setData(filterData);
  //     }
  //     else {
  //         setData(fDatas);
  //     }

  // }

  // const whHandleChange = (e) => {

  //     setWhlist(e.target.value);
  //     var filterData = warehouseList.filter((filterEl) => {
  //         return (filterEl.warehouseList.toLowerCase().includes(e.target.value.toLowerCase()))
  //     })
  //     if (e.target.value != "" && filterData) {
  //         setData(filterData);
  //     }
  //     else {
  //         setData(fDatas);
  //     }

  // }

  //------>>>> Get Data From API --------->>>>>---//

  // const localIndex = localStorage.productIndex

  async function resp() {
    var data = {
      storeLink: localStorage.shopifyUrl,
      accessToken: localStorage.shopifyToken,
    };

    var getProductLocal = localStorage.product?.toString();
    var getProductfirst = localStorage.productfirst?.toString();
    console.log(getProductfirst, "130");

    if (getProductfirst == "true") {
      await axios
        .post(`https://shopifytool-api.webgarh.net/pro/productlist?storeLink=${localStorage.shopifyUrl}`)
        .then((res) => {
          // debugger
          // setAlldata(res.data.data)
          if (!localStorage.addProducts) {
            localStorage.setItem("addProducts", res.data.data.length);
          }

          var add_Products = Number(localStorage.addProducts);

          var vv = [];
          var axcellData = [];
          let imageSrc = [];

          if (getProductLocal == "true") {
            for (var x = 0; x < res.data.data.length; x++) {
              // if (res.data.data[x].image && res.data.data[x].image.length) {
              //   for (let im = 0; im < res.data.data[x].image.length; im++) {
              //     if (res.data.data[x].image[im].src) {
              //       const currentImg = res.data.data[x].image[im];
              //       if (currentImg && currentImg.src) {
              //         imageSrc = res.data.data[x].image[im].src;
              //         const img = new Image();
              //         img.crossOrigin = "*";
              //         img.src = imageSrc;
              //         img.onload = () => {
              //           const canvas = document.createElement("canvas");
              //           const ctx = canvas.getContext("2d");
              //           canvas.width = img.width;
              //           canvas.height = img.height;
              //           ctx.drawImage(img, 0, 0);

              //           // Get the data URL of the image from the canvas

              //           const dataURL = canvas.toDataURL("image/jpeg");
              //           imageSrc = dataURL;
              //           console.log(dataURL, "pars");
              //         };
              //         console.log(imageSrc, "rajesh");
              //         break;
              //       }
              //     }
              //   }
              // } else {
              //   imageSrc = "";
              // }
              //console.log('imggg', res.data.data[x].image[0].src);
              let imgSrc = (res.data.data[x].image != null && res.data.data[x].image != undefined) ? res.data.data[x].image[0].src : '';
              vv.push({
                index: x + 1,
                id: res.data.data[x].id,
                title: res.data.data[x].title,
                image: imgSrc,
                category: res.data.data[x].category,
                subcategory: res.data.data[x].category
                  ? res.data.data[x].category.split(">")
                  : "",
                stock: res.data.data[x].variants
                  ? res.data.data[x].variants[0].inventory_quantity
                  : "",
                vendor: res.data.data[x].vendor,
                price: res.data.data[x].variants
                  ? res.data.data[x].variants[0].price
                  : "",
                id: res.data.data[x].id,
                variants: res.data.data[x].variants ?? [],
                imagesSrc: res.data.data[x].images,
              });
              if (res.data.data.length - 1 == x) {
                setData(vv);
                setFDatas(vv);
                setAllOrders(vv);
                setIsLoad(false);
              }
              axcellData.push({
                index: x + 1,
                id: res.data.data[x].id,
                title: res.data.data[x].title,
                image: imgSrc,
                category: res.data.data[x].category,
                stock: res.data.data[x].variants
                  ? res.data.data[x].variants[0].inventory_quantity
                  : "",
                vendor: res.data.data[x].vendor,
                price: res.data.data[x].variants
                  ? res.data.data[x].variants[0].price
                  : "",
                variants: res.data.data[x].variants ?? [],
              });
              setexcelData(axcellData);
            }
          } else {
            for (var x = 0; x < res.data.data.length; x++) {
              // if (res.data.data[x].image && res.data.data[x].image.length) {
              //   for (let im = 0; im < res.data.data[x].image.length; im++) {
              //     if (res.data.data[x].image[im].src) {
              //       const currentImg = res.data.data[x].image[im];
              //       if (currentImg && currentImg.src) {
              //         imageSrc = res.data.data[x].image[im].src;
              //         break;
              //       }
              //     }
              //   }
              // } else {
              //   imageSrc = "";
              // }
              let imgSrc = (res.data.data[x].image != null && res.data.data[x].image != undefined) ? res.data.data[x].image[0].src : '';
              vv.push({
                index: x + 1,
                id: res.data.data[x].id,
                title: res.data.data[x].title,
                image: imgSrc,
                category: res.data.data[x].category,
                subcategory: res.data.data[x].category
                  ? res.data.data[x].category.split(">")
                  : "",
                stock: res.data.data[x].variants
                  ? res.data.data[x].variants[0].inventory_quantity
                  : "",
                vendor: res.data.data[x].vendor,
                price: res.data.data[x].variants
                  ? res.data.data[x].variants[0].price
                  : "",
                id: res.data.data[x].id,
                variants: res.data.data[x].variants ?? [],
                imagesSrc: res.data.data[x].images,
              });
              if (res.data.data.length - 1 == x) {
                setData(vv);
                setFDatas(vv);
                setAllOrders(vv);
                setIsLoad(false);
              }
              axcellData.push({
                index: x + 1,
                id: res.data.data[x].id,
                title: res.data.data[x].title,
                image: imgSrc,
                category: res.data.data[x].category,
                stock: res.data.data[x].variants
                  ? res.data.data[x].variants[0].inventory_quantity
                  : "",
                vendor: res.data.data[x].vendor,
                price: res.data.data[x].variants
                  ? res.data.data[x].variants[0].price
                  : "",
                variants: res.data.data[x].variants ?? [],
              });
              setexcelData(axcellData);
            }
          }
        })

        .catch((err) => {
          console.log(err);
        });
    }

    //----->>> Get Element ----<<< ----//

    const getTable = document.querySelector("table");
    getTable.setAttribute("id", "table_1_dl");

    await axios
      .post("https://shopifytool-api.webgarh.net/api/webhooks", data)
      .then((res) => {
        console.log("ressssss");
        // setWarehouseList(res.data.webhooks)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const [isLoad, setIsLoad] = useState(true);

  useEffect(() => {
    resp();
    setTimeout(() => setIsLoad(false), 500);
    document.title = "Shopify Tool-Product";
  }, []);

  function convertToExcel(exlData) {
    const worksheet = XLSX.utils.json_to_sheet(exlData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const excelData = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    FileSaver.saveAs(excelData, "Products.xlsx");
  }

  //------->>>>>> CheckBox ------------<<<<<<<<<//////

  const handleAllChecked = (event) => {
    if (event.target.checked) {
      setSelectedItems(data.map((item) => item.id));
    } else {
      setSelectedItems([]);
    }
  };

  const handleCheck = (event, id) => {
    if (event.target.checked) {
      setSelectedItems([...selectedItems, id]);
    } else {
      setSelectedItems(selectedItems.filter((selectedId) => selectedId !== id));
    }
  };

  // <<<<<<<<<<<<<<<------------ Pagination --------->>>>>>>>>>>>>>>>

  const [currentPage, setCurrentPage] = useState(0);

  const [perPage, setPerPage] = useState(10);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const start = currentPage * perPage + 1;
  const end = Math.min((currentPage + 1) * perPage, data.length);

  const unitSelectID = "unit";
  const [unit, setUnit] = useState(10);

  // debugger
  const handlePageChange = useCallback((value) => {
    // debugger
    if (value != 10) {
      setUnit(value);
      setPerPage(value);
    } else {
      setUnit(value);
      setPerPage(value);
    }
  }, []);

  // var aa=data.length

  const options = [
    { label: "10", value: "10" },
    { label: "25", value: "25" },
    { label: "50", value: "50" },
    { label: "100", value: "100" },
    { label: "All", value: data.length.toString() },
  ];

  // ---------->>>>>>>> Row Data ----------<<<<<<<<<<<<//

  var rowsData;
  var rows = [];
  var imgSrc = [];
  var peginData = data.slice(
    currentPage * perPage,
    (currentPage + 1) * perPage
  );

  for (let i = 0; i < peginData.length; i++) {
    var rowC = [];
    var rowV = [];
    var rowS = [];
    var rowP = [];

    for (let ii = 0; ii < peginData[i].variants.length; ii++) {
      const variantId = peginData[i].variants[ii].image_id;
      let matchingImageSrc = "";
      for (let ims = 0; ims < peginData[i].imagesSrc.length; ims++) {
        if (peginData[i].imagesSrc[ims].id === variantId) {
          matchingImageSrc = peginData[i].imagesSrc[ims].src;
          break;
        }
      }
      rowC.push(
        <td style={{ display: "flex", alignItems: "center" }}>
          {peginData[i].subcategory && peginData[i].subcategory.length
            ? peginData[i].subcategory[peginData[i].subcategory.length - 1]
            : "N/A"}
        </td>
      );
      rowV.push(
        <td style={{ display: "flex", alignItems: "center" }}>
          {matchingImageSrc && (
            <img src={matchingImageSrc} style={{ marginRight: "20px" }} />
          )}
          {/* {peginData[i].variants[ii].title } */}
          {/* {peginData[i].variants[ii].title && peginData[i].variants[ii].title=="Default Title" ? peginData[i].variants[ii].title:"N/A"} */}
          {peginData[i].variants[ii].title == "Default Title"
            ? "N/A"
            : peginData[i].variants[ii].title}
        </td>
      );
      rowS.push(
        <td style={{ display: "flex", alignItems: "center" }}>
          {peginData[i].variants[ii].inventory_quantity}
        </td>
      );
      rowP.push(
        <td
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            marginRight: "25px",
          }}
        >
          {peginData[i].variants[ii].price}
        </td>
      );
    }
    rowsData = [
      <div style={{ marginLeft: "20px" }}>
        <td>{peginData[i].index}</td>
      </div>,
      <td>{peginData[i].id}</td>,
      <td>
        {peginData[i].image ? (
          <img src={peginData[i].image} alt="Image" className="w-100 me-auto" />
        ) : (
          <img src={noimg} alt="Image" className="w-100 me-auto" />
        )}
      </td>,
      <td>{peginData[i].title}</td>,
      rowC,
      rowV,
      rowS,
      rowP,
    ];

    rows.push(rowsData);
  }

  const handleShowField = (e) => {
    var get_id = document.getElementById("filter_field_1");
    if (get_id.style.display == "flex") {
      get_id.style.display = "none";
    } else {
      get_id.style.display = "flex";
    }
  };

  // const generatePDF = useReactToPrint({
  //     content: () => conponentPDF.current,
  //     documentTitle: "Products",
  //     // onAfterPrint:()=>alert("Data saved in PDF")
  // });

  async function generatePDF() {
    const element = document.getElementById("pdftable");

    //console.log("element", element.innerHTML);
    const opt = {
      margin: 12.5,
      filename: "Products.pdf",
      image: { type: "jpg", quality: 0.98 },
      html2canvas: { scale: 1, useCORS: true },
      jsPDF: { unit: "mm", format: "a3", orientation: "landscape" },
    };
    
    html2pdf().from(element).set(opt).save();
    // const toDataUrl = async function (url, callback) {
    //   //Convert to base64
    //   return new Promise((resolve, reject) => {
    //     var xhr = new XMLHttpRequest();
    //     xhr.onload = function () {
    //       var reader = new FileReader();
    //       reader.onloadend = function () {
    //         resolve(reader.result);
    //       };
    //       reader.readAsDataURL(xhr.response);
    //     };
    //     xhr.onerror = () => {
    //       reject({
    //         status: this.status,
    //         statusText: xhr.statusText,
    //       });
    //     };
    //     xhr.open("GET", url);
    //     xhr.responseType = "blob";
    //     xhr.send();
    //   });
    // };
    // let imagesUrls = [
    //   "https://img-19.commentcamarche.net/cI8qqj-finfDcmx6jMK6Vr-krEw=/1500x/smart/b829396acc244fd484c5ddcdcb2b08f3/ccmcms-commentcamarche/20494859.jpg",
    //   "https://img-19.commentcamarche.net/cI8qqj-finfDcmx6jMK6Vr-krEw=/1500x/smart/b829396acc244fd484c5ddcdcb2b08f3/ccmcms-commentcamarche/20494859.jpg",
    //   "https://img-19.commentcamarche.net/cI8qqj-finfDcmx6jMK6Vr-krEw=/1500x/smart/b829396acc244fd484c5ddcdcb2b08f3/ccmcms-commentcamarche/20494859.jpg",
    // ];
    // let content = "";
    // content += "<h1> Test of html2pdf </h1>";

    // //Converting to base 64 and adding to content
    // for (let url of imagesUrls) {
    // const element = document.getElementById("pdftable");

    //   let urlBase64 = await toDataUrl(url);
    //   content += '<img src="' + urlBase64 + '" width="600" ><br>';
    // }
    // content += "<h3> End of test </h3>";
    // html2pdf(content, {
    //   margin: 1,
    //   filename: "document.pdf",
    //   image: { type: "jpeg", quality: 0.98 },
    //   html2canvas: { dpi: 192, letterRendering: true },
    //   jsPDF: { unit: "in", format: "letter", orientation: "landscape" },
    // });
  }

  return (
    <>
      <div className="d-flex flex-column flex-root">
        <div className="page d-flex flex-row flex-column-fluid">
          <Leftsidebar />
          <div
            className="wrapper d-flex flex-column flex-row-fluid"
            id="kt_wrapper"
          >
            <Header />
            <div
              className="content d-flex flex-column flex-column-fluid "
              id="kt_content"
            >
              <div className="post d-flex flex-column-fluid" id="kt_post">
                <div id="kt_content_container" className="container-fluid">
                  <div className="card border">
                    {/* <div id="filter_field_1" className="card-header product border-bottom py-4 px-6"> */}
                    <div id="filter_field_1" className="">
                      <Page fullWidth>
                        <Grid>
                          <Grid.Cell
                            columnSpan={{ xs: 5, sm: 3, md: 3, lg: 4, xl: 4 }}
                          >
                            <div className="card-title">
                              <div className="customSearchBox">
                                <div className="input-group position-relative">
                                  <button
                                    onClick={handleSearchClick}
                                    className="btn btn-icon btn-primary customSearchBtn"
                                  >
                                    <i className="las la-search fs-1"></i>
                                  </button>
                                  <input
                                    type="text"
                                    onChange={handleSearchChange}
                                    value={searchData}
                                    className="form-control border mng-hig"
                                    aria-label="Text input with dropdown button"
                                    placeholder="Product name or Id"
                                  />
                                </div>
                              </div>
                            </div>
                          </Grid.Cell>
                          <Grid.Cell
                            columnSpan={{ xs: 3, sm: 1, md: 1, lg: 4, xl: 4 }}
                          ></Grid.Cell>
                          <Grid.Cell
                            columnSpan={{ xs: 4, sm: 2, md: 2, lg: 4, xl: 4 }}
                          >
                            <div className="card-title1">
                              <Grid>
                                <Grid.Cell
                                  columnSpan={{
                                    xs: 4,
                                    sm: 1,
                                    md: 1,
                                    lg: 4,
                                    xl: 4,
                                  }}
                                >
                                  <p className="schange">Show</p>
                                </Grid.Cell>
                                <Grid.Cell
                                  columnSpan={{
                                    xs: 4,
                                    sm: 1,
                                    md: 1,
                                    lg: 4,
                                    xl: 4,
                                  }}
                                >
                                  <Select
                                    id={unitSelectID}
                                    options={options}
                                    onChange={handlePageChange}
                                    value={unit}
                                  />
                                </Grid.Cell>
                                <Grid.Cell
                                  columnSpan={{
                                    xs: 4,
                                    sm: 1,
                                    md: 1,
                                    lg: 4,
                                    xl: 4,
                                  }}
                                >
                                  <p className="schange1">Entries</p>
                                </Grid.Cell>
                              </Grid>
                            </div>
                          </Grid.Cell>
                        </Grid>
                      </Page>
                    </div>
                    {/* <div className="card-title">
                                                <div className="customSearchBox">
                                                    <div className="input-group position-relative">


                                                        <button onClick={handleSearchClick}
                                                            className="btn btn-icon btn-primary customSearchBtn">
                                                            <i className="las la-search fs-1"></i>
                                                        </button>
                                                        <input type="text" onChange={handleSearchChange} value={searchData} className="form-control border mng-hig"
                                                            aria-label="Text input with dropdown button" placeholder="Product name or Id" />
                                                    </div>

                                                </div>
                                                <div className="topDropdownList d-flex gap-3">
                                                    <select className="form-select form-select-border select2-accessible min-w-125px w-125px mng-hig"
                                                        data-control="select2" data-hide-search="true" name="collection"
                                                        data-select2-id="select2-data-10-nfgw1" tabIndex="-1" aria-hidden="true"
                                                        data-kt-initialized="1"
                                                        value={collOption}
                                                        onChange={handleChangeColl}>

                                                        <option value="">
                                                            Collections
                                                        </option>
                                                        {
                                                            collectionData.map((el, ind) => {
                                                                return (
                                                                    <option value={el.collections.id}>{el.collections.id}</option>
                                                                )
                                                            })
                                                        }

                                                    </select>
                                                    <select
                                                        className="form-select form-select-border select2-accessible min-w-125px w-125px mng-hig"
                                                        data-control="select2" data-hide-search="true" name="vendors"
                                                        value={vendorList}
                                                        data-select2-id="select2-data-10-nfgw2" tabIndex="-1" aria-hidden="true"
                                                        data-kt-initialized="1"
                                                        onChange={vendorHandleChange}
                                                    >
                                                        <option value="" >
                                                            Vendors
                                                        </option>
                                                        {
                                                            data.map((el, ind) => {
                                                                return (
                                                                    <option key={ind} value={el.vendor}>{el.vendor}</option>
                                                                )
                                                            })
                                                        }

                                                    </select>
                                                    <select className="form-select form-select-border select2-accessible min-w-125px w-125px "
                                                        data-control="select2" data-hide-search="true" name="wareHouse"
                                                        data-select2-id="select2-data-10-nfgw3" tabIndex="-1" aria-hidden="true"
                                                        data-kt-initialized="1" value={whList} onChange={whHandleChange}>
                                                        <option value="" data-select2-id="select2-data-12-stp73">
                                                            Webhooks
                                                        </option>
                                                        {
                                                            warehouseList.map((el, ind) => {
                                                                return (
                                                                    <option value={el.address}>{el.address}</option>
                                                                )
                                                            })
                                                        }

                                                    </select>

                                                </div>
                                            </div> */}

                    <div className="card-header border-bottom py-4 px-6">
                      <div className="col-sm-12 col-md-8 d-flex align-items-center justify-content-center justify-content-md-start">
                        <h3 className="text-dark fw-bold my-1">Products</h3>
                      </div>
                      <div className="col-sm-12 col-md-4 d-flex align-items-center justify-content-center justify-content-md-end">
                        <div className="listingCounter d-flex align-items-center">
                          <div
                            className="dataTables_info ms-3"
                            id="kt_datatable_example_1_info"
                            role="status"
                            aria-live="polite"
                          >
                            Download Products
                          </div>
                        </div>
                        <div className="ms-3 iconsexport">
                          <div className="exportIcons">
                            <ul>
                              <li>
                                <img
                                  onClick={() => generatePDF(data)}
                                  src="assets/crImages/expotIcon1.png"
                                  alt="Export"
                                />
                              </li>
                              <li>
                                <img
                                  onClick={() => convertToExcel(exlData)}
                                  src="assets/crImages/expotIcon2.png"
                                  alt="Export"
                                />
                              </li>
                            </ul>
                          </div>
                          <div className="dots" onClick={handleShowField}>
                            <span></span>
                            <span></span>
                            <span></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div ref={conponentPDF} style={{ width: "100%" }}>
                      <div
                        className="card-body p-0 table-responsive pro"
                        id="pdftable"
                      >
                        <Page>
                          {
                            data.length !== 0 ? (
                              <Card>
                                <DataTable
                                  columnContentTypes={[]}
                                  headings={[
                                    <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                      <div className="prosrdiv">Sr.No.</div>
                                    </div>,
                                    <div className="prosrdiv">Id</div>,
                                    <div className="text-start">Image</div>,
                                    "Name",
                                    "Category",
                                    "Variants",
                                    "Stock",
                                    <div className="text-end">Price $</div>,
                                  ]}
                                  rows={rows}
                                  hasZebraStripingOnData
                                />
                              </Card>
                            ) : (
                              <Card>
                                <table className="table">
                                  <thead>
                                    <tr>
                                      {/* <th scope="col"><input className='aa form-check-input'  type="checkbox" /></th> */}
                                      <th scope="col">
                                        <div className="prosrdiv1">Sr.No.</div>
                                      </th>
                                      <th scope="col">Id</th>
                                      <th scope="col">Image</th>
                                      <th scope="col">Name</th>
                                      <th scope="col">Category</th>
                                      <th scope="col">Stock</th>
                                      <th scope="col">Price $</th>
                                    </tr>
                                  </thead>
                                  <tbody
                                    style={{
                                      width: "100%",
                                      textAlign: "center",
                                      height: "50vh",
                                    }}
                                  >
                                    <tr>
                                      <th colSpan="6">No Product</th>
                                    </tr>
                                  </tbody>
                                </table>
                              </Card>
                            )
                           
                          }
                        </Page>
                      </div>
                    </div>
                    <div className="card-footer">
                      <p className="me-3">
                        Showing {start} to {end} of {data.length} records
                      </p>
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={Math.ceil(data.length / perPage)}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                      />
                    </div>
                    {isLoad ? (
                      <div className="cLoader">
                        <Loader />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
      <Foot />
    </>
  );
};

export default Product;
