import { Navigate, Outlet } from "react-router";
import auth from './component/Auth';

const useAuth = () => {
  return auth.isLogin();
};

const AuthenticatedRoute = () => {
  const isAuth = useAuth();
  return isAuth ? <Outlet /> : <Navigate to="/" />;
};
export default AuthenticatedRoute;