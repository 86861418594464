import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import auth from "../component/Auth";

// toast.configure();

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  async function Submit(e) {
    e.preventDefault();

    let item = { email, password };
    console.log(item, "item");

    if (email === "" || password === "") {
      toast("All fields are required");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast("Invalid email address");
      return;
    }

    try {
      var data = {
        email: item.email,
        password: item.password
      };

      const res = await axios.post("https://shopifytool-api.webgarh.net/api/login", data);

      if (res.data.data === "login Sucessfully") {
        toast("You are Logged in");
        navigate("/setting");
        window.history.pushState(null, null, window.location.href);
        window.onpopstate = function (event) {
          window.history.go(1);
        };

        auth.loginUser(data.email);
        localStorage.setItem("connect", true);
        localStorage.setItem("loggedin", "You are logged in");
      } else {
        toast(res.data.err);
      }
    } catch (error) {
      console.log("error", error);
      alert("Login Failed");
    }
  }

  return (
    <>
      <div className="d-flex flex-column flex-root login_section">
        <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
          <div className="d-flex flex-center flex-column flex-column-fluid p-10">
            <Link to="/" className="mb-10"></Link>
            <Link to="/" className="brandName mb-10">
              Shopify Tool
            </Link>
            <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
              <form
              // className="form w-100"
              // noValidate="novalidate"
              // id="kt_sign_in_form"
              // action="products.php"
              // method="post"
              >
                <div className="text-center mb-10">
                  <h1 className="text-dark mb-3 fw-semibold">Sign In</h1>
                </div>
                <div className="fv-row mb-10">
                  <label className="form-label fs-6 fw-bold text-dark">
                    Email
                  </label>
                  <input
                    className="form-control form-control-lg form-control-solid"
                    type="text"
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                    autoComplete="off"
                  />
                </div>
                <div className="fv-row mb-10">
                  <div className="d-flex flex-stack mb-2">
                    <label className="form-label fw-bold text-dark fs-6 mb-0">
                      Password
                    </label>
                  </div>
                  <input
                    className="form-control form-control-lg form-control-solid"
                    type="password"
                    name="password"
                    onChange={(e) => setPassword(e.target.value)}
                    autoComplete="on"
                  />
                </div>
                <div className="text-center">
                  <button
                    // type="submit"
                    // id="kt_sign_in_submit"
                    className="btn btn-lg btn-primary w-100 mb-5"
                    onClick={Submit}
                  >
                    <span className="indicator-label">Continue</span>
                    <span className="indicator-progress">
                      Please wait...
                      <span className="spinner-border spinner-border-sm align-middle ms-2" />
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
}

export default Login;
