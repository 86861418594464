import React, { useEffect, useState, useCallback, useRef } from 'react'
import { Page, Card, DataTable, useBreakpoints, Grid, LegacyCard, Select } from '@shopify/polaris';
import Leftsidebar from "../component/leftsidebar";
import Header from "../component/header";
import Footer from "../component/footer";
import Foot from "../component/foot";
import JsPDF from 'jspdf';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import Loader from "../Loader/Loader";
import html2pdf from'html2pdf.js'

import * as XLSX from "xlsx/xlsx";
import FileSaver from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import ReactDOMServer from "react-dom/server";
import { useReactToPrint } from "react-to-print";


import {
  Link
} from 'react-router-dom';
// import "./style.css";
const Transferorders = () => {


  useEffect(() => {
    document.title = "Shopify Tool-Orders"
  })


  const [data, setData] = useState([]);
  const [allOrders, setAllOrders] = useState([]);
  const [exlData, setexcelData] = useState([])
  const conponentPDF = useRef();
  const[ordconnect,setOrdconnect]=useState([])

  const res = (async () => {
    const data = {
      "storeLink": localStorage.shopifyUrl,
      "accessToken": localStorage.shopifyToken

    }
    // console.log(data)
    var getOrderLocal = localStorage.Orders?.toString();
    var getfirstcall = localStorage.firstcall?.toString();

    if(getfirstcall == "true"){
    // if (getOrderLocal == "true") {
      await axios.post(`https://shopifytool-api.webgarh.net/webOrd/orderlisting?storeLink=${localStorage.shopifyUrl}`)
        .then((res) => {
          // debugger
          setOrdconnect(res.data.message)
          // setData(res.data.data);
          // setAllOrders(res.data.data);
          // setFDatas(res.data.data)
          // setIsLoad(false)

          if (!localStorage.addOrders) {
            localStorage.setItem("addOrders", res.data.data.length);
          }
  
          // var add_Orders = Number(localStorage.addOrders);
          var add_Orders = localStorage.getItem("addOrders");
          var add_Webhooks = Number(localStorage.addWebhooks); 
  
          var axcellData = [];
          var adata = [];
  

          if (getOrderLocal=="true") {

            for (let i = 0; i < res.data.data.length; i++) {
              adata.push(res.data.data[i])
              axcellData.push({
                Orders: res.data.data[i].order_number,
                CreatedDate: res.data.data[i].created_at.split("T")[0],
                Items: res.data.data[0].line_items[0].quantity,
                Total$: res.data.data[i].total_price,
              })
              if (res.data.data.length - 1 == i) {
                setData(adata);
                setAllOrders(res.data.data);
                setFDatas(res.data.data)
                setIsLoad(false)
              }
              setexcelData(axcellData);
            }
  
          }
          else{
            for (let i = 0; i < res.data.data.length; i++) {
              adata.push(res.data.data[i])
              axcellData.push({
                Orders: res.data.data[i].order_number,
                CreatedDate: res.data.data[i].created_at.split("T")[0],
                Items: res.data.data[0].line_items[0].quantity,
                Total$: res.data.data[i].total_price,
              })
              if (res.data.data.length - 1 == i) {
                setData(adata);
                setAllOrders(res.data.data);
                setFDatas(res.data.data)
                setIsLoad(false)
              }
              setexcelData(axcellData);
            }
          }


        })
        .catch((err) => {
          console.log(err);
        })
    }
  })




  const [searchData, setSearchData] = useState([]);
  const unitSelectID = "unit";
  const [unit, setUnit] = useState(10);
  const [fDatas, setFDatas] = useState([]);

  const handleSearchClick = (e, data) => {

    var filterData = (!searchData || searchData == "") ? fDatas
      : fDatas.filter((filterEl) => {
        return (filterEl.order_number.toString().includes(searchData.toString()) || filterEl.id.toString().includes(searchData.toString()));
      });
    if (e.target.value != "" && filterData) {
      setData(filterData);
    }
    else {
      setData(fDatas);
    }

  }
  const handleSearchChange = (e) => {
    setSearchData(e.target.value);
    if (!e.target.value) {
      setData(fDatas);
    }
  }

  const options = [
    { label: '10', value: '10' },
    { label: '25', value: '25' },
    { label: '50', value: '50' },
    { label: '100', value: '100' },
    { label: 'All', value: data.length.toString() },
  ];

  const handlePageChange = useCallback((value) => {
    debugger
    if (value != 10) {
      setUnit(value);
      setPerPage(value)
    } else {
      setUnit(value);
      setPerPage(value)
    }
  }, []);


  // <<<<<<<<<<<<<<<------------ Pagination --------->>>>>>>>>>>>>>>>

  const [currentPage, setCurrentPage] = useState(0);

  const [perPage, setPerPage] = useState(10);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const start = currentPage * perPage + 1;
  const end = Math.min((currentPage + 1) * perPage, data.length)

  //<<<<<<<<<<<--------------paginator end ------------>>>>>>>>>>>>>>>


  const [isLoad, setIsLoad] = useState(true);

  function convertToExcel(exlData) {
    const worksheet = XLSX.utils.json_to_sheet(exlData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const excelData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    FileSaver.saveAs(excelData, 'Orders.xlsx');
  }

  // const generatePDF = useReactToPrint({
  //   content: () => conponentPDF.current,
  //   documentTitle: "Orders",
  //   // onAfterPrint:()=>alert("Data saved in PDF")
  // });


  // const generatePDF = (data) => {
  //   // let element = (
  //   //   <div style={{ display: "flex", flexWrap: "wrap" }}>Sample Text</div>
  //   // );
  //   const doc = new jsPDF("p", "px", "a1");
  //   const ele = conponentPDF.current
  //   console.log(ele)
  //   // debugger
  //   doc.html(ele, {
  //     callback: function (doc) {
  //       doc.save('Orders.pdf');
  //     }
  //   })
  // }


  function generatePDF() {
    const element = document.getElementById('table_1_dl');
    const opt = {
    margin:12.5,
    filename: 'Orders.pdf',
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 1 },
    jsPDF: { unit: 'mm', format: 'a3', orientation: 'landscape' },
    };
   
    html2pdf().from(element).set(opt).save();
    }

  useEffect(() => {
    res();
    setTimeout(() => setIsLoad(false), 500)


    const getTable = document.querySelector("table");
    getTable.setAttribute("id", "table_1_dl");
  }, [])
  return (
    <>
      <div className="d-flex flex-column flex-root">
        <div className="page d-flex flex-row flex-column-fluid">
          <Leftsidebar />
          <div
            className="wrapper d-flex flex-column flex-row-fluid"
            id="kt_wrapper"
          >
            <Header />
            <div
              className="content d-flex flex-column flex-column-fluid "
              id="kt_content"
            >
              <div className="post d-flex flex-column-fluid" id="kt_post">
                <div id="kt_content_container" className="container-fluid">
                  <div className="card border">

                    <div id="filter_field_1" className="">

                      <Page fullWidth>
                        <Grid>
                          <Grid.Cell columnSpan={{ xs: 5, sm: 3, md: 3, lg: 4, xl: 4 }}>
                            <div className="card-title">
                              <div className="customSearchBox">
                                <div className="input-group position-relative">


                                  <button onClick={handleSearchClick}
                                    className="btn btn-icon btn-primary customSearchBtn">
                                    <i className="las la-search fs-1"></i>
                                  </button>
                                  <input type="text" onChange={handleSearchChange} value={searchData} className="form-control border mng-hig"
                                    aria-label="Text input with dropdown button" placeholder="Order Id" />
                                </div>

                              </div>
                            </div>
                          </Grid.Cell>
                          <Grid.Cell columnSpan={{ xs: 3, sm: 1, md: 1, lg: 4, xl: 4 }}>
                          </Grid.Cell>
                          <Grid.Cell columnSpan={{ xs: 4, sm: 2, md: 2, lg: 4, xl: 4 }}>
                            <div className="card-title1 " >
                              <Grid>
                                <Grid.Cell
                                  columnSpan={{
                                    xs: 4,
                                    sm: 1,
                                    md: 1,
                                    lg: 4,
                                    xl: 4,
                                  }}
                                >
                                  <p className="schange">Show</p>
                                </Grid.Cell>
                                <Grid.Cell
                                  columnSpan={{
                                    xs: 4,
                                    sm: 1,
                                    md: 1,
                                    lg: 4,
                                    xl: 4,
                                  }}
                                >
                                  <Select
                                    id={unitSelectID}
                                    options={options}
                                    onChange={handlePageChange}
                                    value={unit}
                                  />
                                </Grid.Cell>
                                <Grid.Cell
                                  columnSpan={{
                                    xs: 4,
                                    sm: 1,
                                    md: 1,
                                    lg: 4,
                                    xl: 4,
                                  }}
                                >
                                  <p className="schange1">Entries</p>
                                </Grid.Cell>
                              </Grid>
                            </div>
                          </Grid.Cell>
                        </Grid>
                      </Page>

                    </div>

                    <div className="card-header border-bottom border-top py-4 px-6">
                      <div className="col-sm-12 col-md-8 d-flex align-items-center justify-content-center justify-content-md-start">
                        <h3 className="text-dark fw-bold my-1">
                          Orders
                        </h3>
                      </div>
                      <div className="col-sm-12 col-md-4 d-flex align-items-center justify-content-center justify-content-md-end">
                        <div className="listingCounter d-flex align-items-center">

                          <div
                            className="dataTables_info ms-3"
                            id="kt_datatable_example_1_info"
                            role="status"
                            aria-live="polite"
                          >
                            Download Orders
                          </div>
                        </div>
                        <div className="ms-3 iconsexport">
                          <div className="exportIcons">
                            <ul>
                              <li>
                                <img onClick={generatePDF}
                                  src="assets/crImages/expotIcon1.png"
                                  alt="Export"
                                />
                              </li>
                              <li>
                                <img onClick={() => convertToExcel(exlData)}
                                  src="assets/crImages/expotIcon2.png"
                                  alt="Export"
                                />
                              </li>

                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div ref={conponentPDF} style={{ width: '100%' }}>
                      <div className="card-body p-0 table-responsive" id="table_1_dl">
                        {
                          (data.length !== 0) ?
                            <table
                              className="table table-striped align-middle table-row-solid fs-6 gs-7 gy-5"
                              id="kt_table_users"
                            >

                              <thead>
                                <tr className="text-start text-dark fw-semibold fs-6 gs-0">
                                  {/* <th className="w-200px">Sr.No.</th> */}
                                  <th className="w-200px">Orders No.</th>
                                  <th className="w-200px">Id</th>
                                  <th className="w-200px">Created Date</th>
                                  <th className="w-200px text-center">Items</th>
                                  <th className="w-200px text-end pe-2">Total $</th>
                                </tr>
                              </thead>
                              <tbody className="text-gray-600">

                                {data.slice(currentPage * perPage, (currentPage + 1) * perPage).map((el, index) => {

                                  // console.log(perPage,"354")
                                  // console.log(index,"210")
                                  return (
                                    <tr key={index}>
                                      {/* <td>{index + 1}</td> */}
                                      <td>{el.order_number}</td>
                                      <td>{el.id}</td>
                                      {/* <td>{el.order_number}</td> */}
                                      <td>{el.created_at.split("T")[0]}</td>

                                      <td className="text-center">{el.line_items[0].quantity}</td>
                                      <td className="text-end">{el.total_price}</td>

                                    </tr>
                                  )
                                })

                                }


                              </tbody>
                            </table> :
                            <Card>
                              <table
                                className="table table-striped align-middle table-row-solid fs-6 gs-7 gy-5"
                                id="kt_table_users"
                              >

                                <thead>
                                  <tr className="text-start text-dark fw-semibold fs-6 gs-0">
                                    <th className="w-200px">Id</th>
                                    <th className="w-200px">Orders No.</th>
                                    <th className="w-200px">Created Date</th>
                                    <th className="w-200px text-center">Items</th>
                                    <th className="w-200px text-end pe-2">Total $</th>
                                  </tr>
                                </thead>
                                <tbody className="text-gray-600" style={{ width: "100%", textAlign: "center", height: "50vh" }}>
                                  <tr >
                                    <th colspan="6">No Orders</th>
                                  </tr>
                                </tbody>
                              </table>
                            </Card>
                          // : <table style={{ width: "100%", textAlign: "center", height: "50vh" }}>
                          //   <tr><th>No Orders</th></tr>
                          // </table>
                        }

                      </div>
                    </div>
                    <div className="card-footer">
                      <p className="me-3">Showing {start} to {end} of {data.length} records</p>
                      <ReactPaginate
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        breakLabel={'...'}
                        pageCount={Math.ceil(data.length / perPage)}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                      />

                    </div>
                    {isLoad ?
                      <div className='cLoader'>
                        <Loader />
                      </div>
                      : ""
                    }
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <Foot />
    </>


  )
}

export default Transferorders
