import Product from "./pages/product";

import Transferorders from "./pages/orders";

import Webhooks from "./pages/webhooks";

import Login from "./pages/login";
import {
  BrowserRouter,
  Routes,
  Route,useLocation
} from 'react-router-dom';
import NotFound from "./component/NotFound";
import Settings from "./pages/settings";
import AuthenticatedRoute from "./AuthenticatedRoute"
import UnAuthenticatedRoute from "./UnAthenticatedRoute"



const App = () => {


  return (
    <>
      <BrowserRouter>

        <Routes>
          <Route element={<UnAuthenticatedRoute Routes />}>
            <Route exact path='/' element={<Login />}></Route>
          </Route>

          <Route element={<AuthenticatedRoute Routes />}>
            <Route exact path='/product' element={<Product />}></Route>
            <Route exact path='/setting' element={<Settings />}></Route>
            <Route exact path='/orders' element={<Transferorders />}></Route>
            <Route exact path='/webhooks' element={<Webhooks />}></Route>

            <Route exact path='404' element={<NotFound />}></Route>
            <Route exact path='**' element={<NotFound />}></Route>
          </Route>
        </Routes>

      </BrowserRouter>
    </>
  );
}

export default App;
