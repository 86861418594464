import { Navigate, Outlet } from "react-router";
import auth from './component/Auth';

const useAuth = () => {
    // debugger;
  return auth.isLogin();
};


const UnuthenticatedRoute =() => {    
  const isAuth =   useAuth();
  return !isAuth ? <Outlet /> : <Navigate to="/setting" />;
};
export default UnuthenticatedRoute;