import { useState, useEffect } from "react";
import { Button } from "@shopify/polaris";
import Leftsidebar from "../component/leftsidebar";
import Header from "../component/header";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../component/footer";
import "./style.css";
import { Modal, ModalManager, Effect } from "react-dynamic-modal";
import question from "../assets/question.png";

function Settings() {
  useEffect(() => {
    document.title = "Shopify Tool-Settings";
  }, []);
  function ReactModal({ value, onRequestClose }) {
    return (
      <div className="templo1">
        <Modal onRequestClose={onRequestClose} effect={Effect.Fall}>
          <div className="question_icons">
            <img src={question} alt="" />
          </div>
          <h1 className="del-modal-h1">{value}</h1>
          <div className="del-modal-right-section">
            <button className="singledeleten" onClick={ModalManager.close}>
              No{" "}
            </button>
            <button
              className="singledeletey"
              onClick={() => {
                ConnectStore();
              }}
            >
              Yes
            </button>
          </div>
        </Modal>
      </div>
    );
  }

  function ReactModal1({ value, onRequestClose }) {
    return (
      <div className="templo1">
        <Modal onRequestClose={onRequestClose} effect={Effect.Fall}>
          <div className="question_icons">
            <img src={question} alt="" />
          </div>
          <h1 className="del-modal-h1">{value}</h1>
          <div className="del-modal-right-section">
            <button className="singledeleten" onClick={ModalManager.close}>
              No{" "}
            </button>
            <button
              className="singledeletey"
              onClick={() => {
                handlediscon();
              }}
            >
              Yes
            </button>
          </div>
        </Modal>
      </div>
    );
  }

  const [inputVal, setInputVal] = useState({
    shopifyURL: localStorage.shopifyUrl ? localStorage.shopifyUrl : "",
    shopifyToken: localStorage.shopifyToken ? localStorage.shopifyToken : "",
  });

  const localProduct = localStorage.product;
  const localOrder = localStorage.Orders;
  const localWebhook = localStorage.webhooks;
  const firstcall = localStorage.firstcall;
  const productfirst = localStorage.productfirst;

  const [syncProduct, setSyncProduct] = useState(
    localProduct == "true" ? true : false
  );
  
  const [syncProductfirstconnect, setSyncProductfirstconnect] = useState(
    productfirst == "true" ? true : false
  );

  const [syncOrder, setSyncOrder] = useState(
    localOrder == "true" ? true : false
  );

  const [syncWebhook, setSyncWebhook] = useState(
    localWebhook == "true" ? true : false
  );

  const [syncfirstcall, setfirstcall] = useState(
    firstcall == "true" ? true : false
  );
  const [addToken, setAddToken] = useState("" || localStorage.addToken);
  const [btndata, setBtndata] = useState("");

  const handleProductToggle = () => {
    if (!localStorage.shopifyToken && !localStorage.shopifyUrl) {
      toast("Connect first");
      return;
    } else {
      if (syncProduct === false) {
        localStorage.setItem("product", true);
        toast("Products Webhook Enabled");

        productinsert();
      } else {
        localStorage.setItem("product", false);
        toast("Products Webhook Disabled");
        // handleSave();
      }
      setSyncProduct((current) => !current);
    }
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      if (!localStorage.shopifyToken && !localStorage.shopifyUrl) {
        toast("Connect first");
      }
    }, 500); 
    return () => clearTimeout(timer);
  }, []);
  
  const handleOrderToggle = () => {
    if (!localStorage.shopifyToken && !localStorage.shopifyUrl) {
      toast("Connect first");
      return;
    } else {
      if (syncOrder === false) {
        // localStorage.setItem("Orders", true);
        toast("Orders Webhook Enabled");
        orderinsert();
      } else {
        localStorage.setItem("Orders", false);
        // orderinsert(false);
        toast("Orders Webhook Disabled");
        // handleSave();
      }
      setSyncOrder((current) => !current);
    }
  };
  console.log(syncOrder, "syncOrder");
  const handleWebhookToggle = () => {
    if (!localStorage.shopifyToken && !localStorage.shopifyUrl) {
      toast("Connect first");
      return;
    } else {
      if (syncWebhook == false) {
        localStorage.setItem("webhooks", true);
        toast("URLs Webhook Enabled");
      } else {
        localStorage.setItem("webhooks", false);
        toast("URLs Webhook Disabled");
        // handleSave();
      }
      setSyncWebhook((current) => !current);
    }
  };

  const [value, setvalue] = useState("");
  const openModal = (e) => {
    ModalManager.open(
      <ReactModal text={value} value={e} onRequestClose={() => true} />
    );
  };

  const [value1, setvalue1] = useState("");
  const openModal1 = (e) => {
    ModalManager.open(
      <ReactModal1 text={value1} value={e} onRequestClose={() => true} />
    );
  };

  const handlediscon = (e) => {
    localStorage.removeItem("shopifyUrl");
    localStorage.removeItem("shopifyToken");
    localStorage.removeItem("addToken");
    setAddToken("");
    ModalManager.close();
    setInputVal({
      shopifyURL: "",
      shopifyToken: "",
    });
  };

  const handleSubmitdis = (e) => {
    e.preventDefault();
    openModal1("Do you want to disconnect");
  };

  async function productinsert() {
    const insData = {
      storeLink: inputVal.shopifyURL,
      accessToken: inputVal.shopifyToken,
    };
    await axios
      .post("https://shopifytool-api.webgarh.net/pro/product", insData)
      .then((response) => {
        if (response.data.message == "Product Inserted") {
          localStorage.setItem("productinsertion", true);
        } else {
          console.log("error");
        }
      });
  }

  async function orderinsert() {
    const ordinsData = {
      storeLink: inputVal.shopifyURL,
      accessToken: inputVal.shopifyToken,
    };
    try {
      await axios
        .post("https://shopifytool-api.webgarh.net/webOrd/orders", ordinsData)
        .then((response) => {
          //    debugger
          console.log(response, "response");
          localStorage.setItem("testinbfff", true);
        });
    } catch (err) {
      console.log(err, "errrr");
      alert("err");
    }
  }

  const ConnectStore = async (e) => {
    const reqData = {
      storeLink: inputVal.shopifyURL,
      accessToken: inputVal.shopifyToken,
    };

    try {
      await axios
        .post("https://shopifytool-api.webgarh.net/api/connectStore", reqData, {
          "Access-Control-Allow-Origin": "*",
        })
        .then((res) => {
          if (res.data.message == "Exists" || res.data.message == "Connected") {
            productinsert();
            orderinsert();
            setAddToken(res.data._id);
            setBtndata(res.data.storelink);
            toast("Store Connected Successfully");
            localStorage.setItem(
              "shopifyUrl",
              res.data.storelink || res.data.data.storeLink
            );
            localStorage.setItem("shopifyToken", inputVal.shopifyToken);
            localStorage.setItem("addToken", res.data._id);
            Settings();
          } else {
            console.log("Url or Token are Blank");

            toast("Please enter all the cridentials");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
    ModalManager.close();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const reqData = {
      storeLink: inputVal.shopifyURL,
      accessToken: inputVal.shopifyToken,
    };
    console.log(reqData, "reqDatareqData");
    if (!inputVal.shopifyURL && !inputVal.shopifyToken) {
      toast("All fields are required");
      return;
    } else {
      openModal("Do you want to connect");
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setInputVal({ ...inputVal, [e.target.name]: value });
  };

  const handleSave = (value) => {
    console.log(proCheck, "1  ");
    console.log(OrdCheck, "2");
    console.log(webCheck, "3");
    const activeBtnBody = {
      _id: addToken,
      Product: syncProduct,
      Orders: syncOrder,
      webhooks: syncWebhook,
    };
    // debugger
    console.log(activeBtnBody, "activeBtnBody");
    // if (!localStorage.shopifyToken && !localStorage.shopifyUrl) {
    //   toast("Connect first");
    // } else {
    axios
      .put("https://shopifytool-api.webgarh.net/api/active", activeBtnBody)
      .then((res) => {
        console.log(res, "we");
        // debugger;
        try {
          if (
            localStorage.shopifyUrl !== "" &&
            addToken &&
            localStorage.shopifyToken !== ""
          ) {
            localStorage.setItem("Orders", res.data.data.Orders);
            localStorage.setItem("testinbfff", false);

            localStorage.setItem("product", res.data.data.Product);
            localStorage.setItem("productinsertion", false);
            localStorage.setItem("webhooks", res.data.data.webhooks);
            localStorage.setItem("webhookss", res.data.data.webhooks);

            if (res.data.data.Orders) {
              localStorage.setItem("firstcall", res.data.data.Orders);
            }
            if (res.data.data.Product) {
              localStorage.setItem("productfirst", res.data.data.Product);
            }
            if (
              res.data.data.Product == true ||
              res.data.data.Orders == true ||
              res.data.data.webhooks == true
            ) {
              // toast("Changes Saved Successful");
            } else {
              // toast("Changes Saved Successful");
            }
          }
        } catch (err) {
          console.log(err);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [proCheck, setProChecked] = useState(
    localProduct == "true" ? true : false
  );
  const [OrdCheck, setOrdChecked] = useState(
    localOrder == "true" ? true : false
  );
  const [webCheck, setWebChecked] = useState(
    localWebhook == "true" ? true : false
  );
  console.log("/api/webhooks", localWebhook);
  useEffect(() => {
    if (localStorage.loggedin && !localStorage.Enter) {
      toast("You are logged in successfully");
    }
    setTimeout(() => {
      localStorage.setItem("Enter", true);
    }, "3000");
  }, []);

  useEffect(() => {
    let data = localStorage.getItem("testinbfff");
    // let dats = localStorage.getItem("productinsertion")
    console.log(proCheck, "proCheck");
    console.log(data, "proCheck-data");

    if (proCheck === true) {
      handleSave();
    }
    if (proCheck === false) {
      handleSave();
    }


    // if (proCheck === false && data === "false") {
    //   handleSave();
    // } else {
    //   handleSave();
    // }
  }, [syncProduct]);

  useEffect(() => {
    let data = localStorage.getItem("productinsertion");
    console.log(OrdCheck, "OrdCheck");
    console.log(data, "OrdCheck-data");

    if (OrdCheck === true) {
      handleSave();
    }
    if (OrdCheck === false) {
      handleSave();
    }

    // if (OrdCheck === false && data === "false") {
    //   handleSave();
    // }
    // if (data === "false") {
    //   handleSave();
    // }
  }, [syncOrder]);

  let data = localStorage.getItem("webhookss");
  console.log(data, "datadatadata");
  useEffect(() => {
    let data = localStorage.getItem("webhookss");
    data = JSON.parse(data);
    console.log(webCheck, "webCheck");
    console.log(data, "webCheck-data");

    if (webCheck === true) {
      handleSave();
    }

    // if (webCheck === true && data === true) {
    //   handleSave();
    // } else {
    //   handleSave();
    // }
  }, [syncWebhook]);

  return (
    <>
      <div className="d-flex flex-column flex-root" id="setting-123">
        <div className="page d-flex flex-row flex-column-fluid">
          <Leftsidebar />
          <div
            className="wrapper d-flex flex-column flex-row-fluid"
            id="kt_wrapper"
          >
            <Header />
            <div
              className="content d-flex flex-column flex-column-fluid "
              id="kt_content"
            >
              <div className="post d-flex flex-column-fluid" id="kt_post">
                <div id="kt_content_container" className="container-fluid">
                  <div className="card border align-items-start">
                    <div
                      style={{ width: "100%" }}
                      className="card-header border-bottom py-4 px-6"
                    >
                      <div className="col-sm-12 col-md-8 d-flex align-items-center justify-content-center justify-content-md-start">
                        <h3 className="text-dark fw-bold my-1">Setting</h3>
                      </div>
                    </div>
                    <form className="d-flex align-items-start gap-3 flex-column">
                      <div className="form-group d-flex text-left mb-2">
                        <label>Shopoify URL </label>

                        <input
                          value={inputVal.shopifyURL}
                          className="form-control"
                          name="shopifyURL"
                          onChange={handleChange}
                          type="text"
                        />
                      </div>
                      <div className="form-group d-flex text-left flex-collumn mb-2">
                        <label>Shopify Access Token </label>
                        <input
                          className="form-control"
                          value={inputVal.shopifyToken}
                          name="shopifyToken"
                          onChange={handleChange}
                          type="text"
                        />
                      </div>

                      {!addToken ? (
                        <button
                          className="Polaris-Button mt-3 ms-auto setting-btn"
                          type="submit"
                          value="Connect"
                          onClick={handleSubmit}
                        >
                          Connect
                        </button>
                      ) : (
                        <button
                          className="Polaris-Button mt-3 ms-auto setting-diconbtn"
                          type="submit"
                          value="Connect"
                          onClick={handleSubmitdis}
                        >
                          Disconnect
                        </button>
                      )}
                    </form>
                    <div
                      className="switch_group"
                      style={{ marginLeft: "15px" }}
                    >
                      <div className="form-check form-switch">
                        <label className="toggle">
                          <input
                            className="toggle-checkbox"
                            type="checkbox"
                            onClick={handleProductToggle}
                            role="switch"
                            name="syncProduct"
                            onChange={(e) => {
                              setProChecked(e.target.checked);
                            }}
                            checked={proCheck}
                          />
                          <div className="toggle-switch"></div>
                        </label>

                        <label className="form-check-label text-left col-12">
                          Sync Products
                        </label>
                      </div>

                      <div className="form-check form-switch">
                        <label className="toggle">
                          <input
                            className="toggle-checkbox"
                            onClick={handleOrderToggle}
                            name="syncOrder"
                            type="checkbox"
                            role="switch"
                            onChange={(e) => {
                              setOrdChecked(e.target.checked);
                            }}
                            checked={OrdCheck}
                          />
                          <div className="toggle-switch"></div>
                        </label>

                        <label className="form-check-label text-left col-12">
                          Sync Orders
                        </label>
                      </div>

                      <div className="form-check form-switch">
                        <label className="toggle">
                          <input
                            className="toggle-checkbox"
                            onClick={handleWebhookToggle}
                            name="syncWebhook"
                            type="checkbox"
                            role="switch"
                            onChange={(e) => {
                              setWebChecked(e.target.checked);
                            }}
                            checked={webCheck}
                          />
                          <div className="toggle-switch"></div>
                        </label>

                        <label className="form-check-label text-left col-12">
                          Sync Webhooks URL
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <ToastContainer />
      </div>
    </>
  );
}

export default Settings;
