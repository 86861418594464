import React, { useState, useEffect } from "react";
import Leftsidebar from "../component/leftsidebar";
import Header from "../component/header";
import Footer from "../component/footer";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../Loader/Loader";
import "./style.css";

const Webhooks = () => {
  const [data, setData] = useState([]);
  const [isLoad, setIsLoad] = useState(true);
  
  console.log(data,"webdata");

  useEffect(() => {
  const res = async () => {
    const data = {
      storeLink: localStorage.shopifyUrl,
    };
  
    var getWebhooksLocal = localStorage.webhooks.toString();
    if (getWebhooksLocal == "true") {
      await axios
        .post("https://shopifytool-api.webgarh.net/api/webhooks", data)
        .then((res) => {
      
          console.log("res.data.webhooks", res.data.webhooks);
          setData(res.data.webhooks);
          setIsLoad(false);

          localStorage.setItem("addWebhooks", res.data.webhooks.length);

          var add_Webhooks = Number(localStorage.addWebhooks);

          if (getWebhooksLocal == "true") {
            setData(res.data.webhooks)
            setIsLoad(false)
          }else{
            setData(res.data.webhooks)
            setIsLoad(false)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  res();
  document.title = "Shopify Tool-Webhooks";
}, []);



  // useEffect(() => {
  //   res();
  //   setTimeout(() => setIsLoad(false), 2000);

  //   document.title = "Shopify Tool-Webhooks";
  // }, []);

  return (
    <>
      <div className="d-flex flex-column flex-root">
        <div className="page d-flex flex-row flex-column-fluid">
          <Leftsidebar />
          <div
            className="wrapper d-flex flex-column flex-row-fluid"
            id="kt_wrapper"
          >
            <Header />
            <div
              className="content d-flex flex-column flex-column-fluid "
              id="kt_content"
            >
              <div className="post d-flex flex-column-fluid" id="kt_post">
                <div id="kt_content_container" className="container-fluid">
                  <div className="card border">
                    <div className="card-header border-bottom py-4 px-6">
                      <div className="col-sm-12 col-md-8 d-flex align-items-center justify-content-center justify-content-md-start">
                        <h3 className="text-dark fw-bold my-1">Webhooks</h3>
                      </div>
                    </div>
                    <div className="card-body p-0 table-responsive">
                      {data.length !== 0 ? (
                        <table
                          className="table warehouse table-striped align-middle table-row-solid fs-6 gs-7 gy-5"
                          id="kt_table_users"
                        >
                          <tbody className="text-gray-600">
                            {data.map((el) => {
                              return (
                                <tr>
                                  <td>
                                    {" "}
                                    <span>
                                      <p
                                        style={{ color: "#ff8c00" }}
                                        className="me-1 weba"
                                      >
                                        {el.address}
                                      </p>
                                      {/* <a
                                        href="#"
                                        style={{ color: "#ff8c00" }}
                                        className="me-1 weba"
                                      >
                                        {el.address}
                                      </a> */}
                                    </span>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      ) : (
                        <table
                          style={{
                            width: "100%",
                            textAlign: "center",
                            height: "50vh",
                          }}
                        >
                          <tr>
                            <td>No Webhooks</td>
                          </tr>
                        </table>
                      )}
                    </div>
                    {isLoad ? (
                      <div className="cLoader">
                        <Loader />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Webhooks;
