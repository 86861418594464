import React from 'react'
import {
    Link, NavLink, useLocation
} from 'react-router-dom';
import { Icon } from '@shopify/polaris';
import {
    ProductsMajor, OrdersMinor
} from '@shopify/polaris-icons';
import './style.css';
import webicon from "../assets/webhookicon.png"



const Leftsidebar = () => {

    const location = useLocation()
    var get_pathname = location.pathname;

    return (
        <>
            <div
                id="kt_aside"
                className="aside"
                data-kt-drawer="true"
                data-kt-drawer-name="aside"
                data-kt-drawer-activate="{default: true, lg: false}"
                data-kt-drawer-overlay="true"
                data-kt-drawer-width="{default:'200px', '300px': '250px'}"
                data-kt-drawer-direction="start"
                data-kt-drawer-toggle="#kt_aside_toggle"
            >

                <div
                    className="aside-menu bg-white border  pe-0 ps-0 rounded-2"
                    id="kt_aside_menu"
                >
                    <div
                        className="w-100 mb-2"
                        id="kt_aside_menu_wrapper"
                        data-kt-scroll="true"
                        data-kt-scroll-activate="{default: false, lg: true}"
                        data-kt-scroll-height="auto"
                        data-kt-scroll-dependencies="#kt_aside_footer, #kt_header"
                        data-kt-scroll-wrappers="#kt_aside, #kt_aside_menu, #kt_aside_menu_wrapper"
                        data-kt-scroll-offset={102}
                    >
                        <div
                            className="menu menu-column menu-rounded menu-sub-indention menu-active-bg my-auto"
                            id="#kt_aside_menu"
                            data-kt-menu="true"
                        >
                            {/* <div className="menu-item pt-3">
                                <div className="menu-content">
                                    <span className="menu-heading fw-bold text-uppercase fs-7">
                                        Forecasting
                                    </span>
                                </div>
                            </div> */}
                            <div className="menu-item">
                                <hr style={{ "borderColor": "#fff" }} className="my-1" />
                                <NavLink to="/Setting" className="menu-link">
                                    <span className="menu-icon">
                                        <span className="svg-icon svg-icon-5">
                                            <i className="fa-solid fa-gear"></i>
                                        </span>
                                    </span>
                                    <span className="menu-title">Setting</span>
                                </NavLink>
                            </div>
                            <hr className="bg-gray-400 my-2" />
                            <div className="menu-item">
                                <NavLink to="/product" className={(get_pathname === "/productdetails") ? "menu-link active" : (get_pathname === "/edit_product") ? "menu-link active" : "menu-link"}>
                                    <span className="menu-icon">
                                        <span className="font-awesome">
                                            {/* <i class="fa-solid fa-tag"></i> */}
                                            <Icon
                                                source={ProductsMajor}
                                                color="base"
                                            />
                                        </span>
                                    </span>
                                    <span className="menu-title">Products</span>
                                </NavLink>
                            </div>
                            <hr className="bg-gray-400 my-2" />



                            <div className="menu-item">
                                <NavLink to="/orders" className={(get_pathname === "/createtransfer") ? "menu-link active" : "menu-link"}>
                                    <span className="menu-icon">
                                        <span className="font-awesome">
                                            {/* <i className="fas fa-sync" /> */}
                                            <Icon
                                                source={OrdersMinor}
                                                color="base"
                                            />
                                        </span>
                                    </span>
                                    <span className="menu-title">Orders</span>
                                </NavLink>
                            </div>
                            <hr className="bg-gray-400 my-2" />
                            <div className="menu-item">
                                <NavLink to="/webhooks" className="menu-link">
                                    <span className="menu-icon">
                                        <span className="svg-icon svg-icon-5">
                                        {/* <img className='sideimg' src={webicon} alt="webhook icon"></img> */}
                                            <i className="fa-solid fa-warehouse"></i>
                                            {/* <i className="fa-solid fa-webhook"></i> */}
                                           
                                        </span>
                                    </span>
                                    <span className="menu-title">Webhooks URL</span>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Leftsidebar
